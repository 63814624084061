import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Home from './src/screens/Home';
import Login from './src/screens/Login';
import Register from './src/screens/Register';
import Profile from './src/screens/Profile';
import Cart from './src/screens/Cart';
import Consumptions from './src/screens/Consumptions';
import Bars from './src/screens/Bars';
import Orders from './src/screens/Orders';
import OrderDetails from './src/screens/OrderDetails';
import OrderHistory from './src/screens/OrderHistory';
import OrderHistoryDetails from './src/screens/OrderHistoryDetails';
import OrderGroupHistory from './src/screens/OrderGroupHistory';
import OrderGroupHistoryDetails from './src/screens/OrderGroupHistoryDetails';
import Payment from './src/screens/Payment';


import I18n from './src/utils/i18n';
import OrderGroups from './src/screens/OrderGroups';
import OrderGroupDetails from './src/screens/OrderGroupDetails';
import RegisterWithPhoneNumber from './src/screens/RegisterWithPhoneNumber';
import RegisterWithEmail from './src/screens/RegisterWithEmail';
import { Image, Platform, Text, TouchableOpacity } from 'react-native';
import PaymentStatus from './src/screens/PaymentStatus';
import TwintDirectPayment from './src/screens/TwintDirectPayment';
import OpenUrl from './src/screens/OpenUrl';
import OrdersDisplay from './src/screens/OrdersDisplay';
import CardPayment from './src/screens/CardPayment';


const Stack = createNativeStackNavigator();

export default function App() {

  const screenOptions = {
    animation: 'none',
    headerBackVisible: false,
    headerTitleAlign: 'center',
    headerBackTitleVisible: true,
    // headerShadowVisible: true,
    // headerLeft: (props, props2) => {
    //   console.log(props);
    //   console.log(props.navigate)
    //   if(props.canGoBack) {
    //     return (
    //       <TouchableOpacity style={{flex: 0}}>
    //         <RightChevronIcon width="26" height="26" />
    //         {props.label ? (
    //           <Text style={{color: config.primaryColor, fontSize: 17, fontWeight: '700'}}>{props.label}</Text>
    //         ) : null}
    //       </TouchableOpacity>
    //     )
    //   }
    // }
  }

  let urlParams;
  let barId;
  let tableNumber;
  let paramsObj = {};

  if (Platform.OS === 'web') {
    urlParams = new URLSearchParams(window.location.search);
    barId = urlParams.get('barId');
    tableNumber = urlParams.get('tableNumber');
    if(barId && tableNumber) {
      paramsObj.barId = barId;
      paramsObj.tableNumber = tableNumber;
    }
  }

  return (
    <NavigationContainer linking={{
      prefixes: ['https://kestuboua-app.onrender.com', 'kestuboua://', 'https://app.kestuboua.ch']
    }}>
      <Stack.Navigator initialRouteName="Home" screenOptions={screenOptions}>
        <Stack.Screen name="Home" options={{headerTitle: I18n.t('home')}}>{props => <Home {...props} {...paramsObj} />}</Stack.Screen>
        <Stack.Screen name="Bars" options={{headerTitle: I18n.t('bars'), headerBackVisible: true}} >{props => <Bars {...props} />}</Stack.Screen>
        <Stack.Screen name="Consumptions" options={{headerTitle: I18n.t('consumptions'), headerBackVisible: true}} >{props => <Consumptions {...props} />}</Stack.Screen>
        <Stack.Screen name="Orders" options={{headerTitle: I18n.t('order_tickets')}} >{props => <Orders {...props} />}</Stack.Screen>
        <Stack.Screen name="OrdersDisplay" options={{headerShown: false}} >{props => <OrdersDisplay {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderDetails" options={{headerTitle: I18n.t('order_ticket_details'), headerBackVisible: true}} >{props => <OrderDetails {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderHistory" options={{headerTitle: I18n.t('order_ticket_history'), headerBackVisible: true}} >{props => <OrderHistory {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderHistoryDetails" options={{headerTitle: I18n.t('order_ticket_history_details'), headerBackVisible: true}} >{props => <OrderHistoryDetails {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderGroups" options={{headerTitle: I18n.t('orders')}} >{props => <OrderGroups {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderGroupDetails" options={{headerTitle: I18n.t('order_details'), headerBackVisible: true}} >{props => <OrderGroupDetails {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderGroupHistory" options={{headerTitle: I18n.t('order_history'), headerBackVisible: true}} >{props => <OrderGroupHistory {...props} />}</Stack.Screen>
        <Stack.Screen name="OrderGroupHistoryDetails" options={{headerTitle: I18n.t('order_history_details'), headerBackVisible: true}} >{props => <OrderGroupHistoryDetails {...props} />}</Stack.Screen>
        <Stack.Screen name="Cart"
          options={(props) => ({
            headerTitle: I18n.t('cart'),
            headerRight: () => (
              <TouchableOpacity style={{marginRight: 10}}
                onPress={() => {
                  props.navigation.setParams({handleClearCart: true})
                }}
              >
                <Image
                  source={require('./src/assets/images/clear.png')}
                  style={{width: 35, height: 35}}
                />
              </TouchableOpacity>
            )
          })}
        >
          {props => <Cart {...props} />}
        </Stack.Screen>
        <Stack.Screen name="Payment" options={{headerTitle: I18n.t('payment')}} >{props => <Payment {...props} />}</Stack.Screen>
        <Stack.Screen name="TwintDirectPayment" options={{headerTitle: I18n.t('payment')}} >{props => <TwintDirectPayment {...props} />}</Stack.Screen>
        <Stack.Screen name="CardPayment" options={{headerTitle: I18n.t('payment')}} >{props => <CardPayment {...props} />}</Stack.Screen>
        <Stack.Screen name="Profile" options={{headerTitle: I18n.t('profile')}} >{props => <Profile {...props} />}</Stack.Screen>
        <Stack.Screen name="Login" options={{headerTitle: I18n.t('login'), headerShown: false}}>{props => <Login {...props} />}</Stack.Screen>
        <Stack.Screen name="Register" options={{headerTitle: I18n.t('register'), headerShown: false}}>{props => <Register {...props} />}</Stack.Screen>
        <Stack.Screen name="RegisterWithPhoneNumber" options={{headerTitle: I18n.t('register'), headerShown: false}}>{props => <RegisterWithPhoneNumber {...props} />}</Stack.Screen>
        <Stack.Screen name="RegisterWithEmail" options={{headerTitle: I18n.t('register'), headerShown: false}}>{props => <RegisterWithEmail {...props} />}</Stack.Screen>
        <Stack.Screen name="PaymentSuccess" options={{headerTitle: I18n.t('payment_success'), headerShown: false}}>{props => <PaymentStatus {...props} status="success" />}</Stack.Screen>
        <Stack.Screen name="PaymentFailed" options={{headerTitle: I18n.t('payment_failed'), headerShown: false}}>{props => <PaymentStatus {...props} status="failed" />}</Stack.Screen>
        <Stack.Screen name="PaymentCancel" options={{headerTitle: I18n.t('payment_cancelled'), headerShown: false}}>{props => <PaymentStatus {...props} status="cancel" />}</Stack.Screen>
        <Stack.Screen name="OpenUrl" options={{headerTitle: I18n.t('open_url'), headerShown: false}}>{props => <OpenUrl {...props} />}</Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
