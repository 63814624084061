import React, { Component } from 'react';

import { ScrollView, StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';

import { getPaymentStatus } from '../services/myposCard';
import i18n from '../utils/i18n';



const states = {
    DEFAULT: 1,
    LOADING: 2,
    RESULT: 3,
    NOTHING: 4,
}

let payment_timer = null;

export default class CardPay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            role: this.props.role,
            ruid: this.props.ruid,
            current_state: this.props.ruid ? states.DEFAULT : states.NOTHING,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ruid !== this.props.ruid) {
            this.setState({ruid: this.props.ruid})
        }
    }

    componentWillUnmount() {
        clearInterval(payment_timer);
    }


    _checkPaymentStatus = async (ruid) => {
        try {
            payment_timer = setInterval(async () => {

                let ret = await getPaymentStatus(ruid);
                if(ret.status == "success") {
                    clearInterval(payment_timer);
                    if(this.props.successCallback) {
                        this.props.successCallback(ret);
                    }
                } 
                
                if(ret.status == "in_progress") {
                    this.setState({current_state: states.DEFAULT});
                }

                if(ret.status == "failed" || ret.status == "cancelled") {
                    clearInterval(payment_timer);
                    if(this.props.failureCallback) {
                        this.props.failureCallback(ret);
                    }
                }
            }, 1000);
        } catch (error) {
            console.log("Accept Payment Error", error);
            clearInterval(payment_timer);
            if(this.props.failureCallback) {
                this.props.failureCallback(error);
            }
        }
    }


    getRender = () => {

        switch(this.state.current_state) {

            case states.NOTHING:
                return (
                    <></>
                )

            case states.DEFAULT:

                if (this.props.ruid) {
                    clearInterval(payment_timer);
                    this._checkPaymentStatus(this.props.ruid);
                }

                return (
                    <ScrollView
                        contentContainerStyle={{flex: 1, justifyContent: 'center', alignItems: 'center', padding: 20}}
                    >

                        <View style={styles.container}>
                            <Text style={{color: 'gray', fontWeight: '700', fontSize: 25, marginBottom: 20, marginTop: 20, textAlign: 'center'}}>{i18n.t('payment_in_progress')}</Text>
                            
                            <Text style={{color: 'gray', fontWeight: '700', fontSize: 25, marginBottom: 20, marginTop: 20, textAlign: 'center'}}>{i18n.t('SENTENCE.card_payment_in_progress_text')}</Text>

                            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                                <Image
                                    source={require('./../assets/images/pos_check_button.png')}
                                    style={{height: 250, width: 250}}
                                />

                                {/* <Image
                                    source={require('./../assets/images/pos_machine.gif')}
                                    style={{height: 250, width: 250}}
                                /> */}
                            </View>

                            <TouchableOpacity style={styles.buttonCancelTransaction}
                                onPress={() => {
                                    clearInterval(payment_timer);
                                    if (this.props.cancelCallback) {
                                        this.props.cancelCallback();
                                    }
                                }}
                            >
                                <Text style={{ color: "white", fontSize: 20}}>{i18n.t('cancel_transaction')}</Text>
                            </TouchableOpacity>

                        </View>

                    </ScrollView>
                )

        }
    }


    render(){
        return (
            this.getRender()
        );
    }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    buttonCancelTransaction: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(192, 0, 0, 0.7)',
        width: "90%",
        height: 50,
        justifyContent: 'center',
        borderRadius: 10,
        margin: 10,
        marginTop: 30,
        paddingHorizontal: 20
    }
})
