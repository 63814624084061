import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image, Linking, Platform } from "react-native";
import config from './../config';
import AddressIcon from './../assets/icons/address.svg';
import CashIcon from './../assets/icons/cash_green.svg';
import CardIcon from './../assets/icons/card.svg';
import MasterCardIcon from './../assets/icons/mastercard.svg';
import MaestroIcon from './../assets/icons/maestro.svg';
import TwintAppIcon from './../assets/icons/twint_app.svg';
import VisaIcon from './../assets/icons/visa.svg';
import RefundSuccessIcon from './../assets/icons/refund_success.svg';
import RefundFailedIcon from './../assets/icons/refund_failed.svg';
import RightChevronIcon from './../assets/icons/right-chevron.svg';
import CustomChip from "./CustomChip";
import { formatPriceWithCurrencyCode } from "../utils/common";
import I18n from "../utils/i18n";
import i18n from "../utils/i18n";

let payrexx_status_icon;
let payment_type_icon;

if (Platform.OS == 'web') {
    payrexx_status_icon = {
        'cash' : <Image source={require('./../assets/icons/cash_green.svg')} style={{width: 30, height: 30}} />,
        'card' : <Image source={require('./../assets/icons/card.svg')} style={{width: 30, height: 30}} />,
        'refund_success' : <Image source={require('./../assets/icons/refund_success.svg')} style={{width: 30, height: 30}} />,
        'refund_failed' : <Image source={require('./../assets/icons/refund_failed.svg')} style={{width: 30, height: 30}} />
    }
} else {
    payrexx_status_icon = {
        'cash' : <CashIcon width={30} height={30} />,
        'card' : <CardIcon width={30} height={30} />,
        'refund_success' : <RefundSuccessIcon width={30} height={30} />,
        'refund_failed' : <RefundFailedIcon width={30} height={30} />,
    }
}

if (Platform.OS == 'web') {
    payment_type_icon = {
        'cash' : <Image source={require('./../assets/icons/cash_green.svg')} style={{width: 30, height: 30}} />,
        'card' : <Image source={require('./../assets/icons/card.svg')} style={{width: 30, height: 30}} />,
        'mastercard' : <Image source={require('./../assets/icons/mastercard.svg')} style={{width: 30, height: 30}} />,
        'maestro' : <Image source={require('./../assets/icons/maestro.svg')} style={{width: 30, height: 30}} />,
        'twint' : <Image source={require('./../assets/icons/twint_web.svg')} style={{width: 50, height: 30}} />,
        'visa' : <Image source={require('./../assets/icons/visa.svg')} style={{width: 30, height: 30}} />,
    }
} else {
    payment_type_icon = {
        'cash' : <CashIcon width={30} height={30} />,
        'card' : <CardIcon width={30} height={30} />,
        'mastercard' : <MasterCardIcon width={30} height={30} />,
        'maestro' : <MaestroIcon width={30} height={30} />,
        'twint' : <TwintAppIcon width={30} height={30} />,
        'visa' : <VisaIcon width={30} height={30} />,
    }
}

class OrderCard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    openGps = (lat, lng, title = null) => {
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${lat},${lng}`;
        const label = title ? title : latLng;
        const url = Platform.select({
            ios: `${scheme}${label}@${latLng}`,
            android: `${scheme}${latLng}(${label})`,
            web: `https://www.google.com/maps/search/?api=1&query=${latLng}`
        });    
        Linking.openURL(url);
    }
    
    render() {
        return (
            <TouchableOpacity
                style={
                    this.props?.highlighted ?
                    {backgroundColor: 'white', padding: 10, paddingTop: 0, margin: 5, flex: 1, flexDirection: 'column', borderRadius: 7, borderWidth: 3, borderColor: config.primaryColor} :
                    {backgroundColor: 'white', padding: 10, paddingTop: 0, margin: 5, flex: 1, flexDirection: 'column', borderRadius: 7}
                }
                disabled={this.props?.disabled}
                onPress={this.props?.onClick}
            >

                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 5}}>
                    <View style={{justifyContent: 'center'}}>
                        <View>
                            {(this.props?.waitingBeforeMe) ? (
                                <Text style={{fontWeight: '500', fontSize: 15, color: 'red'}}>{i18n.t('there_are') + ' ' + this.props?.waitingBeforeMe + ' ' + i18n.t('orders_left_before_yours')}</Text>
                            ) : null}
                            {(this.props?.beeperNumber) ? (
                                <Text style={{fontWeight: '500', fontSize: 15, color: 'black'}}>
                                    {I18n.t('beeper_number') + ': ' + this.props.beeperNumber}
                                </Text>
                            ) : null}
                            <Text style={{fontWeight: '700', fontSize: 20, marginTop: 5, color: 'black'}}>{formatPriceWithCurrencyCode(this.props?.total, this.props?.currencyCode)}</Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            {(this.props?.paymentType in payment_type_icon) ? (
                                <View style={{marginRight: 10}}>
                                    {payment_type_icon[this.props?.paymentType]}
                                </View>
                            ) : null}
                            {(this.props?.status == config.orderStatus.CANCELLED.key && this.props?.payrexxStatus == "refunded") && (
                                payrexx_status_icon['refund_success']
                            )}
                            {(this.props?.status == config.orderStatus.CANCELLED.key && this.props?.payrexxStatus != "refunded") && (
                                payrexx_status_icon['refund_failed']
                            )}
                        </View>
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <View style={{justifyContent: 'center'}}>
                            {(this.props?.orderNumber) ? (
                                <View style={{borderRadius: 25, borderColor: config.primaryColor, borderWidth: 2}}>
                                    <Text style={{fontWeight: '700', fontSize: 25, padding: 10, color: 'black'}}>
                                        {this.props.orderNumber}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                    </View>
                </View>

                {(this.props?.user) ? (
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 5}}>
                        <View style={{justifyContent: 'center'}}>
                            <Text style={{fontWeight: '500', fontSize: 14, color: 'black'}}>{this.props.user?.fullname ? this.props.user.fullname : this.props.user.username}</Text>
                        </View>
                    </View>
                ) : null}

                

                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    {this.props?.description ? (
                        <View style={{justifyContent: 'center'}}>
                            <Text style={{fontSize: 12, color: 'gray', fontWeight: '600'}}>{this.props?.description}</Text>
                        </View>
                    ) : null}
                    <View style={{justifyContent: 'center'}}>
                        {this.props?.status == config.orderStatus.PAYMENT_PENDING.key ? 
                            <CustomChip text={config.orderStatus.PAYMENT_PENDING.name} backgroundColor={"white"} color={"orange"} borderColor={"orange"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.WAITING_FOR_PAYMENT.key ? 
                            <CustomChip text={config.orderStatus.WAITING_FOR_PAYMENT.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.PAYMENT_FAILED.key ? 
                            <CustomChip text={config.orderStatus.PAYMENT_FAILED.name} backgroundColor={"white"} color={"red"} borderColor={"red"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.PENDING.key ? 
                            <CustomChip text={config.orderStatus.PENDING.name} /> 
                        : null}
                        {this.props?.status == config.orderStatus.CONFIRMED.key ? 
                            <CustomChip text={config.orderStatus.CONFIRMED.name} /> 
                        : null}
                        {this.props?.status == config.orderStatus.PROCESSING.key ? 
                            <CustomChip text={config.orderStatus.PROCESSING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.READY.key ? 
                            <CustomChip text={config.orderStatus.READY.name} backgroundColor={"white"} color={"green"} borderColor={"green"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.COMPLETED.key ? 
                            <CustomChip text={config.orderStatus.COMPLETED.name} backgroundColor={"green"} color={"white"} borderColor={"green"} /> 
                        : null}
                        {this.props?.status == config.orderStatus.CANCELLED.key ? 
                            <CustomChip text={config.orderStatus.CANCELLED.name} backgroundColor={"red"} color={"white"} borderColor={"red"} /> 
                        : null}
                    </View>
                </View>

                {this.props?.secondary_title ? (
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View style={{justifyContent: 'center'}}>
                            <Text style={{fontSize: 12, color: 'gray', fontWeight: '700'}}>{this.props?.secondary_title}</Text>
                        </View>
                        {(this.props?.onClick) ? (
                            <View style={{justifyContent: 'center'}}>
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 20, height: 20}} />
                                ) : (
                                    <RightChevronIcon width={20} height={20} />
                                )}
                            </View>
                        ) : null}
                    </View>
                ) : null}


                {(this.props?.address || this.props?.geo_location) ? (
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, alignItems: 'stretch'}}>
                        {this.props?.address ? (
                            <View style={{justifyContent: 'center', width: '70%'}}>
                                <Text style={{color: 'black'}}>{this.props?.address?.addressLine1}</Text>
                            </View>
                        ) : <View></View>}
                        {this.props?.geo_location ? (
                            <View style={{justifyContent: 'center', align: 'right'}}>
                                <TouchableOpacity
                                    style={{width: 60, height: 40, borderRadius: 10, backgroundColor: config.primaryColor, justifyContent: 'center', alignItems: 'center'}}
                                    onPress={() => this.openGps(this.props?.geo_location?.latitude, this.props?.geo_location?.longitude)}
                                >
                                    {(Platform.OS === 'web') ? (
                                        <Image source={require('./../assets/icons/address.svg')} style={{width: 20, height: 20}} />
                                    ) : (
                                        <AddressIcon width={20} height={20} />
                                    )}
                                </TouchableOpacity>
                            </View>
                        ) : null}
                        
                    </View>
                ) : null}

            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    navigation_button_text: {
        color: 'gray'
    },
    buttonGroup: {
        height: 50,
        padding: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        // backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 1)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        borderWidth: 2,
    },
    wideTouchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        // width: 80,
        paddingLeft: 20,
        paddingRight: 20,
        height: 40
        // borderWidth: 3,
    },
    touchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40
        // borderWidth: 3,
    },
    wideButtonText: {
        fontSize: 20,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    buttonText: {
        fontSize: 29,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    count:{
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        width: 40,
        textAlign: 'center',
        color: 'black'
    },
    addButtonGroup: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        // width: 120,
        // height: 40
    },
    buttonView: {
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5
    },
    addText: {
        fontSize: 18,
        fontWeight: '500',
        color: 'white'
    }
});

export default OrderCard;