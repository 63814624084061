export default {
    hello: 'Bonjour',
    welcome_back: 'Bienvenue',
    email_or_username: 'Email ou nom d\'utilisateur',
    password: 'Mot de passe',
    login: 'Connexion',
    dont_have_an_account: 'Vous n\'avez pas de compte?',
    register: 'S\'inscrire maintenant',
    create_an_account: 'Créer un compte',
    already_have_an_account: 'Vous avez déjà un compte?',
    email: 'Email',
    username: 'Nom d\'utilisateur',
    full_name: 'Nom complet',
    confirm_password: 'Confirmer le mot de passe',
    passwords_didnt_match: 'Les mots de passe ne correspondent pas',
    invalid_email: 'Email invalide',
    username_already_exists: 'Nom d\'utilisateur déjà utilisé',
    registration_successful: 'Inscription réussie',
    unknown_error_occurred: 'Erreur inconnue',
    please_login_again: 'Veuillez vous reconnecter',
    error: 'Erreur',
    user_not_found: 'Utilisateur non trouvé',
    ok: 'OK',
    loading: 'Chargement',
    please_wait: 'Veuillez patienter',
    registered_successfully: 'Inscription réussie',
    add: 'Ajouter',
    invalid_token: 'Token invalide',
    home: 'Accueil',
    cart: 'Panier',
    profile: 'Profil',
    success: 'Succès',
    consumptions_added_to_cart: 'Consommations ajoutées au panier',
    unknown_error: 'Erreur inconnue',
    add_to_cart: 'Ajouter au panier',
    total_items: 'Total des articles',
    total_cost: 'Coût total',
    cart_saved: 'Panier sauvegardé',
    cart_is_empty: 'Le panier est vide',
    order_placed: 'Commande passée',
    orders: 'Commandes',
    order_id: 'ID de commande',
    pending: 'En attente',
    confirmed: "En file d'attente",                 // 'Confirmed' is changed to 'In queue' for better understanding
    processing: 'En traitement',
    ready: 'Prêt',
    completed: 'Terminé',
    cancelled: 'Annulé',
    payment_pending: 'Paiement en attente',
    payment_processing: 'Paiement en cours de traitement',
    payment_failed: 'Paiement échoué',
    paymentPending: 'Paiement en attente',
    paymentProcessing: 'Paiement en cours de traitement',
    paymentFailed: 'Paiement échoué',
    logout: 'Déconnexion',
    no_orders_yet: 'Pas encore de commandes',
    no_order_tickets_yet: 'Pas encore de tickets de commande',
    no_skistations_found: 'Aucune station de ski trouvée',
    no_bars_found: 'Aucun bar trouvé',
    no_consumptions_found: 'Aucune consommation trouvée',
    go_to_cart: 'Aller au panier',
    view_orders: 'Voir les commandes',
    not_yet_implemented: 'Pas encore implémenté',
    confirm_order: 'Confirmer la commande',
    process_order: 'Traiter la commande',
    order_ready: 'Commande prête',
    complete_order: 'Terminer la commande',
    do_you_want_to_confirm_order: 'Voulez-vous confirmer la commande?',
    do_you_want_to_process_order: 'Voulez-vous traiter la commande?',
    do_you_want_to_change_status_to_ready: 'Voulez-vous changer le statut à prêt?',
    do_you_want_to_complete_order: 'Voulez-vous terminer la commande?',
    yes: 'Oui',
    no: 'Non',
    change_password: 'Changer le mot de passe',
    old_password: 'Ancien mot de passe',
    new_password: 'Nouveau mot de passe',
    new_password_can_not_be_same_as_old_password: 'Le nouveau mot de passe ne peut pas être le même que l\'ancien',
    new_password_and_confirm_password_didnt_match: 'Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas',
    incorrect_old_password: 'Ancien mot de passe incorrect',
    password_updated: 'Mot de passe mis à jour',
    incorrect_password: 'Mot de passe incorrect',
    always_on_display: 'Affichage permanent',
    cannot_place_order_from_multiple_ski_stations: 'Vous ne pouvez pas passer de commande à partir de plusieurs stations de ski',
    save_cart: 'Sauvegarder le panier',
    place_order: 'Passer la commande',
    continue_to_order: 'Continuer la commande',
    clear_cart: 'Vider le panier',
    warning: 'Attention',
    do_you_want_to_clear_cart: 'Voulez-vous vider le panier?',
    cancel: 'Annuler',
    cart_is_now_empty: 'Le panier est maintenant vide',
    order_number: 'Numéro de commande',
    cancel_order: 'Annuler la commande',
    do_you_want_to_cancel_order: 'Voulez-vous annuler la commande?',
    do_you_want_to_make_order_pending: 'Voulez-vous rendre la commande en attente?',
    payment_successful: 'Paiement réussi',
    payment_failed: 'Paiement échoué',
    unable_to_cancel_this_order: 'Impossible d\'annuler cette commande',
    error_retrieving_transaction: 'Erreur lors de la récupération de la transaction',
    transaction_not_found: 'Transaction non trouvée',
    gateway_not_found: 'Passerelle non trouvée',
    order_cancelled_successfully: 'Commande annulée avec succès',
    order_not_found: 'Commande non trouvée',
    order_already_cancelled: 'Commande déjà annulée',
    transaction_not_found: 'Transaction non trouvée',
    order_cancelled_but_an_error_occured_during_refund: 'Commande annulée, mais une erreur s\'est produite lors du remboursement',
    order_cancelled_but_unable_to_make_refund: 'Commande annulée, mais impossible de faire un remboursement',
    pay_now: 'Payer maintenant',
    do_you_want_to_pay_now: 'Voulez-vous payer maintenant?',
    cancel_request: 'Annuler la demande',
    unable_to_pay_now: 'Impossible de payer maintenant',
    unable_to_pay_for_this_order: 'Impossible de payer pour cette commande',
    are_you_sure_you_want_to_change_bar_status: 'Êtes-vous sûr de vouloir changer le statut du bar?',
    order_history: 'Historique des commandes',
    this_app_version_is_not_supported: 'Cette version de l\'application n\'est pas prise en charge',
    please_update_your_app: 'Veuillez mettre à jour votre application',
    app_version: 'Version de l\'application',
    change_language: 'Changer de langue',
    bars: 'Bars',
    consumptions: 'Consommations',
    order_details: 'Détails de la commande',
    order_history_details: 'Détails de l\'historique des commandes',
    payment: 'Paiement',
    password_should_be_atleast_6_characters: 'Le mot de passe doit comporter au moins 6 caractères',
    i_forgot_my_password: 'J\'ai oublié mon mot de passe',
    send_mail: 'Envoyer un mail',
    closed: 'Fermé',
    refresh: 'Rafraîchir',
    refreshing: 'Rafraîchissement',
    load_more: 'Charger plus',
    step_back: "Revenir à l'étape précédente",
    others: 'Autres',
    some_consumptions_are_not_added_to_cart_do_you_want_to_go_back_without_adding_them: 'Certaines consommations ne sont pas ajoutées au panier. Voulez-vous revenir sans les ajouter?',
    one_or_more_bar_not_currently_open: 'Un ou plusieurs bars ne sont pas actuellement ouverts',
    bar_not_currently_open: 'Le bar n\'est pas actuellement ouvert',
    incorrect_username_or_password: 'Nom d\'utilisateur ou mot de passe incorrect',
    no_order_selected: 'Aucune commande sélectionnée',
    go_to_orders: 'Aller aux commandes',
    beeper_number: 'Numéro de beeper',
    paid_by_cash: 'Payé en espèces',
    paid_by_card: 'Payé par carte',
    order_cancelled_please_refund_the_client: 'Commande annulée, veuillez rembourser le client',
    printer_ip: 'IP de l\'imprimante',
    mark_as_completed: 'Marquer comme terminé',
    in_progress: 'En cours',
    daily_report: 'Rapport quotidien',
    actual_turnover: 'Chiffre d\'affaires réel',
    expected_turnover: 'Chiffre d\'affaires attendu',
    generate_report: 'Générer un rapport',
    cash_turnover: 'Chiffre d\'affaires en espèces',
    card_turnover: 'Chiffre d\'affaires par carte',
    password_changed: 'Mot de passe changé',
    cash: 'Espèces',
    card: 'Carte',
    online: 'En ligne',
    quick_order: 'Commande rapide',
    delete_my_account_and_erase_data: 'Supprimer mon compte et effacer les données',
    are_you_sure: 'Êtes-vous sûr?',
    do_you_want_to_delete_account_and_erase_data_this_operation_is_irreversible_and_takes_30_days_for_completion: 'Voulez-vous supprimer votre compte et effacer les données? Cette opération est irréversible et prend 30 jours pour être terminée',
    delete_account: 'Supprimer le compte',
    keep_account: 'Conserver le compte',
    something_went_wrong: 'Quelque chose s\'est mal passé',
    email_already_exists: 'L\'email existe déjà',
    order_groups: 'Groupes de commandes',
    order_group: 'Groupe de commandes',
    order_group_id: 'ID du groupe de commandes',
    order_group_details: 'Détails du groupe de commandes',
    no_order_groups_yet: 'Pas encore de groupes de commandes',
    id: 'ID',
    network_error: 'Erreur réseau',
    table: 'Table',
    or: 'ou',
    continue_with_phone_number: 'Continuer avec le numéro de téléphone',
    continue_with_email: 'Continuer avec l\'email',
    phone_number: 'Numéro de téléphone',
    invalid_phone_number: 'Numéro de téléphone invalide',
    invalid_otp: 'OTP invalide',
    invalid_code: 'Code invalide',
    send_otp: 'Envoyer un OTP',
    send_code: 'Envoyer un code',
    otp: 'OTP',
    code: 'Code',
    otp_sent_to: 'OTP envoyé à',
    code_sent_to: 'Code envoyé à',
    resend_otp_in: 'Renvoyer l\'OTP dans',
    resend_code_in: 'Renvoyer le code dans',
    seconds: 'secondes',
    resend_otp: 'Renvoyer l\'OTP',
    resend_code: 'Renvoyer le code',
    validate: 'Valider',
    edit: 'Modifier',
    otp_should_be_3_characters: 'L\'OTP doit comporter 3 caractères',
    code_should_be_3_characters: 'Le code doit comporter 3 caractères',
    bar_id_is_required: 'L\'ID du bar est requis',
    failed: 'Échoué',
    online_payment: 'Paiement en ligne',
    refunded: 'Remboursé',
    do_you_want_to_pay_by_cash: 'Voulez-vous payer en espèces?',
    do_you_want_to_pay_by_card: 'Voulez-vous payer par carte?',
    do_you_want_to_receive_payment_online: 'Voulez-vous recevoir le paiement en ligne?',
    do_you_want_to_receive_payment_by_cash: 'Voulez-vous recevoir le paiement en espèces?',
    do_you_want_to_receive_payment_by_card: 'Voulez-vous recevoir le paiement par carte?',
    do_you_want_to_cancel_all_orders: 'Voulez-vous annuler toutes les commandes?',
    order_group_cancelled_and_refunded_successfully: 'Commandes annulé et remboursé avec succès',
    order_tickets: 'Tickets de commande',
    order_ticket_details: 'Détails du ticket de commande',
    order_group_cancelled_please_refund_the_client: 'Commandes annulé, veuillez rembourser le client',
    kot: 'KOT',
    table_number: 'Numéro de table',
    scan_qr_code: 'Scanner le code QR',
    order_ticket_history: 'Historique des tickets de commande',
    order_ticket_history_details: 'Détails de l\'historique des tickets de commande',
    update_cart: 'Mettre à jour le panier',
    save_to_cart: 'Enregistrer dans le panier',
    go_to_cart_and_clear_to_order_from_another_bar: 'Aller au panier et vider pour commander dans un autre bar',
    country_code: 'Code pays',
    invalid_country_code: 'Code pays invalide',
    scan_and_order: 'Scanner et commander',
    scan_and_pay: 'Scanner et payer',
    no_order_found: 'Aucune commande trouvée',
    unable_to_pay_at_the_moment: 'Impossible de payer pour le moment',
    you_can_only_add_items_from_one_bar_at_a_time: 'Vous ne pouvez ajouter des articles que d\'un seul bar à la fois',
    currently_not_available: 'Actuellement indisponible',
    available_from: 'Disponible à partir de',
    available_to: 'Disponible jusqu\'à',
    to: 'à',
    available_between: 'Disponible entre',
    and: 'et',
    waiting: 'En attente',
    paid: 'Payé',
    waiting_for_payment: 'En attente de paiement',
    payment_failed: 'Paiement échoué',
    to_be_paid: 'À payer',
    app_version_missing: 'Version de l\'application manquante',
    app_version_too_old: 'Version de l\'application trop ancienne',
    reload: 'Recharger',
    bar_is_closed_permanantly: 'Le bar est fermé de façon permanente',
    beeper: 'Beeper',
    invalid_fullname: 'Nom complet invalide',
    unauthorized: 'Non autorisé',
    report_generated: 'Rapport généré',
    continue: 'Continuer',
    guest_account: 'Compte invité',
    all_saved_data_will_be_lost_upon_logout: 'Toutes les données enregistrées seront perdues lors de la déconnexion',
    do_you_want_to_logout: 'Voulez-vous vous déconnecter?',
    continue_without_an_account: 'Continuer sans compte',
    please_enable_notification_permission_to_get_updates_on_your_orders: 'Veuillez activer l\'autorisation de notification pour recevoir des mises à jour sur vos commandes',
    new: 'Nouveau',
    please_close_this_tab: 'Veuillez fermer cet onglet',
    payment_successful: 'Paiement réussi',
    payment_failed: 'Paiement échoué',
    payment_cancelled: 'Paiement annulé',
    order_group_already_checked_out: 'Commande déjà vérifiée',
    app_version_too_old: 'Version de l\'application trop ancienne',
    unable_to_save_hidden_bar_locally: 'Impossible de sauvegarder le bar caché localement',
    unable_to_remove_hidden_bar_locally: 'Impossible de supprimer le bar caché localement',
    unable_to_get_local_hidden_bars: 'Impossible d\'obtenir les bars cachés localement',
    added_from_scanned_qr: 'Ajouté à partir du code QR scanné',
    failed_to_create_payment_gateway: 'Impossible de créer la passerelle de paiement',
    order_updates: 'Mises à jour de commande',
    please_scan_the_following_twint_qr_code: "Veuillez scanner le code QR Twint suivant",
    switch_to_the_app_now: "Passez à l'application maintenant",
    pay_and_place_order: "Payer et passer la commande",
    pay_for_full_order: "Payer pour la commande complète",
    pay_for_this_order_ticket: "Payer pour ce ticket de commande",
    tickets: "Tickets",
    paid_by_twint: "Payé par Twint",
    copy_code: "Copier le code",
    copied: "Copié",
    copied_to_clipboard: "Copié dans le presse-papiers",
    enter_this_code_on_your_twint_app: " Entrer ce code dans votre aplication TWINT",
    payment_in_progress: "Paiement en cours",
    cancel_transaction: "Annuler la transaction",
    pay_by_card: "Payer par carte",
    pay_by_twint: "Payer par Twint",
    twint: "Twint",
    twint_not_enabled: "Twint n'est pas activé",
    payrexx_details_not_found: "Détails Payrexx non trouvés",
    pay_for_full_order_by_card: "Payer pour la commande complète par carte",
    pay_for_full_order_by_twint: "Payer pour la commande complète par Twint",
    do_you_want_to_receive_payment_by_twint: "Voulez-vous recevoir le paiement par Twint?",
    pay_later: "Payer plus tard",
    do_you_want_to_receive_payment_later: "Voulez-vous recevoir le paiement plus tard?",
    there_are: "Il reste",
    orders_left_before_yours: "commandes avant la vôtre",
    pay: "Payer",
    payrexx: "Payrexx",
    people_waiting: "Personnes en attente",
    expected: "Attendu",
    error_updating_online_hidden: "Erreur lors de la mise à jour en ligne cachée",
    please_enter_table_number: "Veuillez entrer le numéro de table",
    update_app: "Mettre à jour l'application",
    country_list: {
        switzerland: 'Suisse',
        germany: 'Allemagne',
        france: 'France',
        italy: 'Italie',
        united_kingdom: 'Royaume-Uni',
        united_states: 'États-Unis',
        india: 'Inde'
    },
    SENTENCE: {
        forgot_password_text: "Veuillez nous envoyer un mail à info@jm-contactless.ch avec vos coordonnées et nous vous aiderons à récupérer votre compte",
        card_payment_in_progress_text: "Demande envoyée au terminal, veuillez cliquer sur le bouton de vérification sur le terminal",
    },
    OPTIONS: {
        timestampFormat: 'DD.MM.YYYY HH:mm',
        timeFormat: 'HH:mm',
    },
    LOCATION_ERROR: {
        PERMISSION_DENIED: 'Autorisation de localisation refusée',
        PERMISSION_DENIED_INFO: 'Veuillez activer l\'autorisation de localisation pour obtenir des bars près de chez vous',
        PERMISSION_DENIED_SECONDARY_INFO: 'Cliquez pour activer',
        POSITION_UNAVAILABLE: 'Position indisponible',
        PERMISSION_UNAVAILABLE_INFO: 'Veuillez recharger la page pour réessayer',
        TIMEOUT: 'Temps écoulé',
        UNKNOWN_ERROR: 'Erreur inconnue'
    },
};