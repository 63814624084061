import i18n from "./utils/i18n"

const CURRENT_APP_VERSION = "2.2.00"

const roles = {
    USER:{
        value: 5,
        key: "user"
    },
    SERVER:{
        value: 10,
        key: "server"
    },
    CASHIER:{
        value: 13,
        key: "cashier"
    },
    BAROWNER:{
        value: 15,
        key: "barowner"
    },
    ADMIN:{
        value: 20,
        key: "admin"
    },
    SUPERADMIN:{
        value: 30,
        key: "superadmin"
    }
}

const orderStatus = {
    PAYMENT_PENDING: {
        key: "paymentPending",
        name: i18n.t('payment_pending')
    },
    WAITING_FOR_PAYMENT: {
        key: "waitingForPayment",
        name: i18n.t('waiting_for_payment')
    },
    PAYMENT_FAILED: {
        key: "paymentFailed",
        name: i18n.t('payment_failed')
    },
    PENDING: {
        key: "pending",
        name: i18n.t('pending')
    },
    CONFIRMED: {
        key: "confirmed",
        name: i18n.t('confirmed')
    },
    PROCESSING: {
        key: "processing",
        name: i18n.t('processing')
    },
    READY: {
        key: "ready",
        name: i18n.t('ready')
    },
    COMPLETED: {
        key: "completed",
        name: i18n.t('completed')
    },
    CANCELLED: {
        key: "cancelled",
        name: i18n.t('cancelled')
    }
}


// If the orderGroupPaymentStatus is 'pending', it means the order is getting ready but needs to be paid
// If the orderGroupPaymentStatus is 'waiting', it means the order is not placed yet and is waiting for payment. Only after payment, order will be placed
const orderGroupPaymentStatus = {
    PENDING: {
        key: "pending",
        name: i18n.t('to_be_paid')
    },
    COMPLETED: {
        key: "completed",
        name: i18n.t('paid')
    },
    FAILED: {
        key: "failed",
        name: i18n.t('payment_failed')
    },
    REFUNDED: {
        key: "refunded",
        name: i18n.t('refunded')
    },
    WAITING: {
        key: "waiting",
        name: i18n.t('waiting_for_payment')
    
    }
}

const orderGroupStatus = {
    ACTIVE: {
        key: "active",
        name: i18n.t('active')
    },
    INACTIVE: {
        key: "inactive",
        name: i18n.t('inactive')
    },
    CANCELLED: {
        key: "cancelled",
        name: i18n.t('cancelled')
    }
}

const paymentType = {
    CASH: {
        key: "cash",
        name: i18n.t('cash')
    },
    CARD: {
        key: "card",
        name: i18n.t('card')
    },
    ONLINE: {
        key: "online",
        name: i18n.t('online')
    }
}

const paymentMethod = {
    PAYREXX: {
        key: "payrexx",
        name: "Payrexx"
    },
    TWINT: {
        key: "twint",
        name: "Twint"
    }
}

const groupStatus = {
    ACTIVE: {
        key: "active",
        name: i18n.t('active')
    },
    INACTIVE: {
        key: "inactive",
        name: i18n.t('inactive')
    }
}

const categoryStatus = {
    ACTIVE: {
        key: "active",
        name: i18n.t('active')
    },
    INACTIVE: {
        key: "inactive",
        name: i18n.t('inactive')
    }
}

const consumptionStatus = {
    ACTIVE: {
        key: "active",
        name: i18n.t('active')
    },
    INACTIVE: {
        key: "inactive",
        name: i18n.t('inactive')
    }
}

const consumptionInputType = {
    INPUT: {
        key: "input",
    },
    QUANTITY: {
        key: "quantity",
    }
}

const consumptionColors = {
    'purple': '#a269ff80',
    'red': '#ff5436cc',
    'orange': '#ffcd81',
    'gray': '#a6a4a3',
    'green': '#6dc778',
}

export default {
    primaryColor: "#08607e",
    primaryColorLight: "rgba(8, 96, 126, 0.1)",
    dangerColor: "#ad2923",
    backgroundColor: "lightgray",
    baseUrl: (process.env.NODE_ENV == "development") ? "https://kestuboua-be.onrender.com/api" : "/api",
    webUrl: (process.env.NODE_ENV == "development") ?  "https://kestuboua-app.onrender.com" : "",
    tokenKey: "token",
    roleKey: "role",
    roleValueKey: "value",
    barsKey: "bars",
    fcmTokenKey: "fcmToken",
    hiddenBarsKey: "hiddenBars",
    localeKey: "locale",
    currencyCode: "CHF",
    orderLimit: 30,
    allOrderLimit: 10,
    paginationLimit: 10,
    orderRefreshInterval: (process.env.NODE_ENV == "development") ? 60 : 3,        // in seconds
    orderRefreshIntervalLong: (process.env.NODE_ENV == "development") ? 120 : 5,   // in seconds
    orderGroupRefreshInterval: (process.env.NODE_ENV == "development") ? 60 : 3,   // in seconds
    orderGroupRefreshIntervalLong: (process.env.NODE_ENV == "development") ? 120 : 5,   // in seconds
    roles,
    orderStatus,
    orderGroupPaymentStatus,
    orderGroupStatus,
    groupStatus,
    categoryStatus,
    consumptionStatus,
    CURRENT_APP_VERSION,
    paymentType,
    paymentMethod,
    consumptionInputType,
    consumptionColors
}