import commonService from './common';

const server = commonService.getAxiosInstance();

const update_category_online_hidden = async (category_id, online_hidden) => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/categories/${category_id}/onlineHidden`, {
            online_hidden
        }, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    update_category_online_hidden
}
