import commonService from './common';

const server = commonService.getAxiosInstance();

const get_consumptions = async (bar_id=null) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let url = '/consumptions?status=active';
        if (bar_id) {
            url += `&barId=${bar_id}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const update_consumption_online_hidden = async (consumption_id, online_hidden) => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/consumptions/${consumption_id}/onlineHidden`, {
            online_hidden
        }, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    get_consumptions,
    update_consumption_online_hidden
}
