import commonService from './common';
import storageService from './storage';

const server = commonService.getAxiosInstance();

const get_bars = async (query={}) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let queryString = commonService.getQueryStringFromObject(query);
        const response = await server.get(`/bars?${queryString}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error?.response?.data ? error.response.data : { error: 'Network error', error_code: '|network_error|'};
    }
}

const get_bar = async (id) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/bars/${id}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const update_bar = async (id, payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/bars?${id}`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const save_hidden_bar_locally = async (barId) => {
    try {
        let hidden_bars = await storageService.getHiddenBars();
        // check if barId already exists in hidden_bars
        if (hidden_bars.includes(barId)) {
            return true;
        }
        hidden_bars.push(barId);
        await storageService.setHiddenBars(hidden_bars);
        return true;
    } catch (error) {
        return { error: 'Unable to save hidden bar locally', error_code: '|unable_to_save_hidden_bar_locally|' }
    }
}

const remove_hidden_bar_locally = async (barId) => {
    try {
        let hidden_bars = await storageService.getHiddenBars();
        // check if barId already exists in hidden_bars
        if (!hidden_bars.includes(barId)) {
            return true;
        }
        hidden_bars = hidden_bars.filter((id) => id !== barId);
        await storageService.setHiddenBars(hidden_bars);
        return true;
    } catch (error) {
        return { error: 'Unable to remove hidden bar locally', error_code: '|unable_to_remove_hidden_bar_locally|' }
    }
}

const get_local_hidden_bars = async () => {
    try {
        return await storageService.getHiddenBars();
    } catch (error) {
        return { error: 'Unable to get local hidden bars', error_code: '|unable_to_get_local_hidden_bars|' }
    }
}

const get_people_waiting = async (barId) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/bars/${barId}/peopleWaiting`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    get_bars,
    get_bar,
    update_bar,
    save_hidden_bar_locally,
    remove_hidden_bar_locally,
    get_local_hidden_bars,
    get_people_waiting
}
