import commonService from './common';

const server = commonService.getAxiosInstance();

const getDailyStatistics = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/day`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const printDailyStatistics = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/day/print`;
        const response = await server.post(url, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const getWeekStatistics = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/week`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const getMonthStatistics = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/month`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const getFirstLastOrder = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/orders/first_last`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const getBarWiseDailyStatistics = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/day/bars`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const getDayExpectedTurnover = async (barId) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/statistics/bar/${barId}/day/expected_turnover`;
        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    getDailyStatistics,
    printDailyStatistics,
    getWeekStatistics,
    getMonthStatistics,
    getFirstLastOrder,
    getBarWiseDailyStatistics,
    getDayExpectedTurnover
}