import React from "react";
import { Text, View, TouchableOpacity, StyleSheet, Image, Platform } from "react-native";
import Modal from "react-native-modal";
import config from "../config";
import { isMiniHeight, isTooMiniHeight } from "../utils/common";

import I18n from "../utils/i18n";
import { useNavigation } from "@react-navigation/native";
import authService from "../services/auth";

class AlertPopUpModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: true,
            timeout: false
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                timeout: true
            })
        }, 15000);
    }


    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        return (
            <Modal 
                isVisible={this.props?.isVisible ? (this.props.isVisible && this.state.isModalVisible) : false}
                animationIn='pulse'
                children={this.props.children}
                style={(Platform.OS == "web") ? {
                    marginTop: isMiniHeight() ? (isTooMiniHeight() ? '0vh' : '10vh') : '18vh',
                    marginBottom: isMiniHeight() ? (isTooMiniHeight() ? '0vh' : '10vh') : '18vh',
                    backgroundColor: 'white',
                    padding: 30,
                    borderRadius: 35,
                    maxWidth: '100vw',
                    minHeight: '50%',
                    maxHeight: '100vh'
                } : {
                    marginTop: isMiniHeight() ? (isTooMiniHeight() ? undefined : '10%') : '18%',
                    marginBottom: isMiniHeight() ? (isTooMiniHeight() ? undefined : '10%') : '18%',
                    backgroundColor: 'white',
                    padding: 30,
                    borderRadius: 35,
                    minHeight: '50%',
                }}
            >

            {(this.props?.icon == 'success') ? (
                <View style={styles.iconContainer}>
                    <Image
                        source={require('./../assets/images/success.gif')}
                        style={{height: 200, width: 200}}
                    />
                </View>
            ) : null}

            {(this.props?.icon == 'error') ? (
                <View style={styles.iconContainer}>
                    <Image
                        source={require('./../assets/images/error.gif')}
                        style={{height: 200, width: 200}}
                    />
                </View>
            ) : null}

            {(this.props?.icon == 'warning') ? (
                <View style={styles.iconContainer}>
                    <Image
                        source={require('./../assets/images/warning.gif')}
                        style={{height: 200, width: 200}}
                    />
                </View>
            ) : null}
            
            {(this.props?.icon == 'info') ? (
                <View style={styles.iconContainer}>
                    <Image
                        source={require('./../assets/images/info.gif')}
                        style={{height: 200, width: 200}}
                    />
                </View>
            ) : null}
            
            {(this.props?.icon == 'loading') ? (
                <View style={styles.iconContainer}>
                    <Image
                        source={require('./../assets/images/loading.gif')}
                        style={{height: 200, width: 200}}
                    />
                </View>
            ) : null}

            <Text style={this.props?.titleStyle ? {...styles.title, ...this.props.titleStyle} : styles.title}>
                {this.props?.title ? this.props.title : ''}
            </Text>
            <Text style={this.props?.textStyle ? {...styles.text, ...this.props.titleStyle} : styles.text}>
                {this.props?.text ? this.props.text : ''}
            </Text>
            {this.props.okCallback ? (
                <TouchableOpacity style={{...styles.okButton, ...this.props?.okButtonStyle}}
                    onPress={() => this.props.okCallback() }
                >
                    <Text style={[{ color: "white", fontSize: 20, textAlign: 'center'}]}>
                        {this.props?.okText ? this.props.okText : I18n.t('ok')}
                    </Text>
                </TouchableOpacity>
            ) : null}


            {this.props.cancelCallback ? (
                <TouchableOpacity style={{...styles.cancelButton, ...this.props?.cancelButtonStyle}}
                    onPress={() => this.props.cancelCallback() }
                >
                    <Text style={[{ color: "white", fontSize: 20}]}>
                        {this.props?.cancelText ? this.props.cancelText : I18n.t('cancel')}
                    </Text>
                </TouchableOpacity>
            ) : null}

            {(this.props?.icon == "loading" && this.state.timeout) ? (
                <TouchableOpacity style={styles.cancelRequestButton}
                    onPress={() => {
                        this.setState({
                            isModalVisible: false
                        })
                        if(this.props.cancelRequestCallback)
                            this.props.cancelRequestCallback();
                    }}
                >
                    <Text style={[{ color: "white", fontSize: 20}]}>
                        {this.props?.cancelText ? this.props.cancelText : I18n.t('cancel_request')}
                    </Text>
                </TouchableOpacity>
            ) : null}

            {(this.props?.text == I18n.t('invalid_token')) ? (
                <TouchableOpacity style={styles.cancelRequestButton}
                    onPress={async () => {
                        await authService.logout();
                        this.setState({
                            isModalVisible: false
                        })
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        });
                    }}
                >
                    <Text style={[{ color: "white", fontSize: 20}]}>
                        {this.props?.reloadText ? this.props.reloadText : I18n.t('reload')}
                    </Text>
                </TouchableOpacity>
            ) : null}

            {this.props?.children ? this.props.children : null}

            </Modal>
        );
    }
}

// Wrap and export navigation
export default function(props) {
    const navigation = useNavigation();
  
    return (
      <>
        <AlertPopUpModal {...props} navigation={navigation} />
      </>
    );
}

const styles = StyleSheet.create({
    iconContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white"
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'black',
    },
    okButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: config.primaryColor,
        width: "100%",
        minHeight: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    },
    cancelButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'gray',
        width: "100%",
        minHeight: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    },
    cancelRequestButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: config.dangerColor,
        width: "100%",
        minHeight: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    }
});