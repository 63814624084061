import React from 'react';
import { StyleSheet } from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import config from '../config';
import authService from '../services/auth';
import I18n from "../utils/i18n";

class AppVersionCheck extends React.Component {
  constructor(props) {
    super(props);

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      loading: false,
      isModalVisible: false,
      appVersionError: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      }
    };
  }

  async componentDidMount() {

    // Check App Version
    let current_app_version = config.CURRENT_APP_VERSION;
    let app_version = await authService.getAppVersion();
    let required_app_version = app_version.requiredAppVersion;
    let warning_app_version = app_version.warningAppVersion;

    if (current_app_version < required_app_version) {
      await this.setState({
        appVersionError: true,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('this_app_version_is_not_supported') + ' - ' + current_app_version,
          text: I18n.t('please_update_your_app'),
          okCallback: null
        }
      });
      return;
    }

    if (current_app_version < warning_app_version) {
      await this.setState({
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'warning',
          title: I18n.t('please_update_your_app'),
          okCallback: async () => {
            await this.setState({
              isModalVisible: false
            });
          }
        }
      });
    }
    
    try {
      let verified = await authService.verifyToken();
      if(verified?.error_code == 'invalid_token' || verified?.error_code == '|invalid_token|') {
        await authService.logout();
      }
    } catch (error) { }
  }

  render() {
    return (
        <>
            <AlertPopUpModal
                isVisible={this.state.isModalVisible}
                icon={this.state.modal.icon}
                title={this.state.modal.title}
                text={this.state.modal.text}
                okText={this.state.modal.okText}
                okCallback={this.state.modal.okCallback}
                cancelCallback={this.state.modal.cancelCallback}
            ></AlertPopUpModal>
        </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    padding: 15,
    margin: 20,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: config.primaryColor
  },
  inputHeader: {
    // margin: 10,
    padding: 15,
    paddingBottom: 10,
    fontSize: 15,
    color: 'black'
  },
  input: {
    // borderWidth: 0,
    // borderColor: config.primaryColor,
    borderRadius: 5,
    height: 50,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderWidth: 1,
    margin: 10,
    marginTop: 0,
    padding: 15,
    color: 'black'
  },
  scrollView: {
    backgroundColor: 'white',
    marginHorizontal: 0
  },
  text: {
    fontSize: 42,
  },
  button: {
    backgroundColor: config.primaryColor,
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  disabled_button: {
    backgroundColor: 'gray',
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
  }
});

export default AppVersionCheck;
